<template>
    <v-card
    v-if="loggedIn"
    class="mx-auto mt-6"
        >    
        <v-toolbar
            ligth
            cards                
            flat
            color="green lighten-4"
        >
            <v-card-title class="text-h6 font-weight-regular">
            Spritzpläne
            </v-card-title>
            <v-spacer> </v-spacer>
            <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                                color="primary"
                                dark
                                fab
                                v-bind="attrs"
                                v-on="on"
                                class="mt-10"
                                @click="addItem"
                            >
                                <v-icon dark>
                                    mdi-playlist-plus
                                </v-icon>
                            </v-btn>
                    </template>
                    <span>Spritzplan hinzufügen</span>                           
                </v-tooltip>
                <v-dialog v-model="ctrlAEItem.isVisisble" :width="ctrlAEItem.width" >
                <ctrlAddEditItem 
                    ref="ctrlAEItem" 
                    :field="ctrlAEItem.field"
                    :options="ctrlAEItem.options"
                    :isVisible.sync="ctrlAEItem.isVisisble"
                    @close="ctrlAEItem.isVisisble = false"
                >                        
                </ctrlAddEditItem>              
            </v-dialog>                  
        </v-toolbar>
        <v-card-title></v-card-title>
 
          
    </v-card>

</template>


<script>
export default {

    components: {
        ctrlAddEditItem: () => import('../../components/ctrl/addEditProtectionplan.vue'),
    },  

    data () {
        return {
            name: "Protectionplan",
            compid:'',

            ctrlAEItem:{
                isVisisble: false,
                width: 900,
                item:{},
                options:{
                    title: 'Spritzplan hinzufügen',   
                    center: undefined,
                    isEdit: false
                }

            },            
 
        }
    },

  
    computed: {

   

        loggedIn: function() {

            return this.$store.state.auth.status.loggedIn
            
        },
 
        
    },
    methods:{


        addItem () {
            console.debug('addItem');
            this.$router.push({ name: 'PlantprotectionPlanAddEdit' })  
            // this.ctrlAEItem.options.isEdit = false;
            //            setTimeout(() => {
            //     this.ctrlAEItem.isVisisble = !this.ctrlAEItem.isVisisble;
            // }, 100);
        },

        refreshContent(){
            console.debug('refreshContent');

            // this.$store.dispatch("app/getApiSetStore", {path:"api/company/access", param:{store:"access", id:this.company.id, content:"fields"}
            // }).then( ()=>{ 
            //     return this.$store.dispatch("app/getApiSetStore", {path:"api/company/fields/cultivationspecies", param:{store:"availableCultivationSpecies", id:this.company.id}})
            // }).then( ()=>{ 
            //     return this.$store.dispatch("app/getApiSetStore", {path:"api/company/vinicultures/types", param:{store:"availableVinicultureTypes", id:this.company.id}})
            // }).then( ()=>{    
            //    return this.$store.dispatch("app/getApiSetStore", {path:"api/company/fields", param:{store:"fields", id:this.company.id}})
            // }).then( ()=>{    
            //         console.debug('sdsdsd')  
            // }).catch((error)=>{
            //     console.error(error);
            // })




        },
    },

    mounted() {

        console.debug('mounted'); 
        this.refreshContent();

    },

    watch: { 

    }      

}
</script>
